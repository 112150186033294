<template>
  <div class="blog_page">
    <HeaderWrp/>
    <div class="my_container">
      <Breadcrumbs>
        <router-link :to="{name: 'Home', lang: langStorage}">{{ $t('article.home') }}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path
              d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z"
              fill="#000001"/>
          <path
              d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z"
              fill="#000001"/>
        </svg>
        <a class="curr" style="cursor:pointer">{{ $t('article.blog') }}</a>
      </Breadcrumbs>
    </div>
    <div class="my_container blog_flex">
      <div class="about__txt" v-for="item in responceData" :key="item.id">
        <!--      <div class="top">-->
        <!--        <span class="desc_short inner-p" v-html="item.description">-->
        <!--        </span>-->
        <!--        <a class="view" @click="$router.push({name: 'article', params: { id: item.id, lang: langStorage }})">-->
        <!--          {{ $t('article.readMore') }}-->
        <!--          <img src="/img/success-forward.svg" alt="arrow-img">-->
        <!--        </a>-->
        <!--      </div>-->
        <div class="banner blog_img"
             @click="$router.push({name: 'article', params: { id: item.id, lang: langStorage }})">
          <img :src="item.image" alt="">
        </div>
        <div class="blog_description_container">
          <strong class="main_title">
            {{ item.title }}
          </strong>
        </div>
      </div>
      <!-- <div class="load__more" style="">
        <span>Загрузи больше</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99593 5H9.99593V10H8.99593V6.493C7.6106 7.07272 6.46865 8.1141 5.76404 9.44027C5.05942 10.7664 4.83559 12.2956 5.13056 13.7681C5.42553 15.2406 6.22111 16.5656 7.38217 17.518C8.54323 18.4705 9.99819 18.9916 11.4999 18.993C13.0945 18.9946 14.6341 18.4099 15.8257 17.3502C17.0173 16.2906 17.7778 14.8299 17.9625 13.246C18.1473 11.6621 17.7433 10.0656 16.8275 8.76015C15.9118 7.45471 14.5481 6.53145 12.9959 6.166V5.142C14.7152 5.49183 16.2574 6.43335 17.3542 7.80274C18.4511 9.17213 19.0331 10.8827 18.9991 12.6369C18.965 14.3911 18.3171 16.0778 17.168 17.4036C16.0188 18.7294 14.4413 19.6104 12.7097 19.8933C10.9782 20.1762 9.20224 19.843 7.69092 18.9518C6.17961 18.0606 5.02856 16.6678 4.43805 15.0157C3.84754 13.3635 3.85493 11.5567 4.45895 9.9094C5.06296 8.26215 6.22537 6.87879 7.74393 6H4.99593V5Z" fill="#930684"></path>
        </svg>
      </div> -->
    </div>
    <FooterApp/>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import HeaderWrp from "@/components/HeaderWrp";
import FooterApp from "@/components/FooterApp";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters } from 'vuex';
const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    Breadcrumbs,
    HeaderWrp,
    FooterApp,
    VueSlickCarousel
  },
  beforeMount() {
    this.getBlog();
  },
  data() {
    return {
      responceData: [],
    }
  },
  watch: {
    langStorage() {
      this.getBlog();
    }
  },
  computed: {
    ...mapGetters({
      langStorage: 'languages/getLang'
    })
  },
  methods: {
    async getBlog() {
      try {
        let request = await fetch(`${domain}/${this.langStorage}/get-post/`);
        if (request.ok) {
          let result = await request.json();
          this.responceData = result;
        } else {
          console.error(request);
        }
      } catch (ex) {
        console.error(ex);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
html body {
  overflow-y: hidden;
}
body.night {
  .blog_page {
    .blog_flex {
      .about__txt {
        .blog_description_container {
          strong.main_title {
            color: white;
          }
        }
      }
    }
  }
}
.blog_flex {
  gap: 20px;
  margin: 38px auto 36px;
  position: relative;
  justify-content: center;
  max-width: 1772px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

@media(min-width: 1280px) and (max-width: 1800px) {
  .blog_page .about__txt .top span {
    max-height: 133px !important;
  }
}

@media(max-width: 920px) {
  .blog_flex {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media(max-width: 720px) {
  .blog_flex {
    grid-template-columns: 1fr 1fr;
  }
}

@media(min-width: 1200px) {
  .about__txt .top {
    margin-bottom: 24px
  }
}

.blog_page {

  .about__txt {
    //max-width: 418px;
    //max-height: 498px;
    margin: 0 auto 100px;

    .top {
      flex-direction: column;
    }

    .banner {
      cursor: pointer;

      img {
        @media(min-width: 1200px) {
          object-position: left top;
        }
      }
    }
  }

  .about__txt .top {
    width: 288px;

    strong {
      width: 50%;
      margin: 0;
      user-select: none;
    }

    .main_title {
      display: inline-block;
      width: auto;
      font-weight: 500;
      height: 65px;
      font-size: 20px;
      line-height: 30px;
      overflow: hidden;
    }

    span {
      width: 100%;
      margin-top: 15px;
      max-height: 138px;
      overflow: hidden;

      user-select: none;
    }
  }

  .view {
    display: flex;
    align-items: center;
    color: #930684;
    font-size: 14px;
    line-height: 22px;
    margin-top: 15px;
    margin-left: 15px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;

    img {
      margin-left: 10px;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      img {
        margin-left: 20px;
      }
    }
  }

  .load__more {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    width: 185px;
    margin-left: 50%;
    transform: translateX(-50%);

    span {
      font-size: 20px;
      line-height: 30px;
      color: #930684;
      margin-right: 8px;
    }

    svg {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .desc_short {
    strong, p {
      font-weight: 300;
      font-size: 20px;
      line-height: normal;
    }
  }

}

.blog_page {
  .blog_img {
    img {
      height: 414px;
      width: 414px;
      object-fit: cover;
    }
  }
}

@media (max-width: 1830px) {
  .blog_page {
    .blog_img {
      min-height: 350px;
      img {
        width: 350px;
        object-fit: cover;
      }
    }
  }
  .about__txt {
    max-width: 350px;
    height: 350px;
  }
}

@media (max-width: 1450px) {
  .blog_page {
    .blog_img {
      min-height: 284px;
      img {
        height: 284px;
        width: 284px;
        object-fit: cover;
      }
    }
  }
  .about__txt {
    max-width: 284px;
  }
}

@media (max-width: 1080px) {
  .blog_page {
    .blog_img {
      min-height: 180px;
      img {
        height: 180px;
        width: 180px;
      }
    }
    .about__txt {
      margin: 0px auto 0px;
      max-width: 180px;
    }
  }
}

@media (max-width: 430px) {
  .blog_page .blog_img img {
    height: 343px;
    width: auto;
  }

  blog_page {
    .blog_img {
      img {
        height: 100px;
        width: 100px;
      }
    }
  }
  .about__txt {
    max-width: 100px;
  }

  .about__txt .banner {
    width: 100%;
  }

  .blog_page .blog_flex {
    display: flex;
    flex-direction: column;
  }

  .blog_page .about__txt {
    margin-top: 36px;
    gap: 24px;
    margin-bottom: 0;
    width: 343px;
    height: 364px;
    max-width: none;
    max-height: none;
  }
}

@media (max-width: 345px) {
  .blog_page {
    .about__txt {
      max-width: 193px;
    }
  }
}

.blog_description_container {
  margin-top: 20px;
}

@media (max-width: 1024px) and (min-width: 430px) {
  .about__txt .top strong {
    font-size: 28px;
    line-height: 40px;
  }
  .about__txt {
    margin: 0px auto 100px;

    .top span {
      font-size: 20px;
      line-height: 30px;
      max-height: 32px !important;
    }
  }
  .blog_page {
    .about__author {
      margin-bottom: 100px;
    }
  }
}
</style>